<template>
  <thead>
  <tr  :style="`background-color : ${selectable && semiSelected ? wsBACKGROUND : wsLIGHTCARD};`" >


    <th v-if="selectable || enableDrag"
        @click="$emit('select-all')"
        :style="selectColumnStyle"
        style="padding: 0"
        width="10px"
        class="pl-4 pr-2 pointer"
    >
      <v-icon v-if="selectable" size="18" :class="[{'ml-1' : enableDrag}]" :color="wsACCENT" >{{ selectIcon }}</v-icon>
    </th>

    <th v-if="selectable && semiSelected" :colspan="colspanValue -1" style="border-radius: 0 8px 0 0  !important; position: relative">
      <div v-if="!SM" style="position: absolute;left : 0; top: 10px;z-index: 3"  class="d-flex align-center pl-n8" ref="multiple_items_action">
        <ws-button
            v-for="(item,i) in multipleActionsFiltered" :key="i"
            @click="item.action"
            :label="item.text"
            :left-icon="item.icon"
            :color="wsACCENT"
            text
            height="28"
            class="px-2 mr-2 "
            :ref="`multiple_action_${i}`"
        />

        <v-sheet color="transparent" min-width="28px" min-height="5">

          <ft-select v-if="foldedItems.length" :items="foldedItems" >
              <v-icon v-ripple>mdi-dots-horizontal</v-icon>
          </ft-select>
        </v-sheet>


      </div>

      <div class="d-flex justify-space-between align-center" style="position: relative; ">
        <div >
          <ft-select v-if="SM" :items="multipleActions" >
            <ws-button text :color="wsACCENT" :click-stop="false" v-ripple label="Actions" icon="mdi-menu-down" />
          </ft-select>
        </div>
        <h5 style="font-size: 13px;" class="wsACCENT font-weight-regular" ref="selected_count">
          {{ $t('Selected') }} {{ countSelected }} {{$t('from')}} {{ countTotal }}
        </h5>
      </div>

    </th>
    <template v-else>
      <template v-for="(header,i) in headersFiltered">
        <th  v-if="header.align !== 'd-none'"
             :key="i + 'header'"
             :class="header.align ? `text-${header.align}` : ''"
             :style="getColumnStyle(i , header)"
             class="pointer pa-0"
             @click="header.sort !== false ?  on.sort(header.value) : null"
        >
          <v-hover v-slot="{hover}">
            <v-sheet color="transparent" class="d-flex px-5 align-center fill-height"  >
              <span :style="`color : ${hover || sortOn(header.value) ? wsDARKER : wsACCENT }`" style="transition: all 0.3s ease">{{ header.name ? $t(header.name) :  header.text  }}</span>
              <v-sheet v-if="header.sortable !== false" color="transparent" width="20">
                <v-fade-transition>
                  <v-icon
                      v-if="hover || sortOn(header.value) "
                      :color="sortOn(header.value)  ? 'black' : '' "
                      :class="[{rotate : sortDesc() && sortOn(header.value) }]"
                      style="transform-origin: center;"
                      small>
                    mdi-arrow-up
                  </v-icon>

                </v-fade-transition>
              </v-sheet>
            </v-sheet>
          </v-hover>



        </th>
      </template>
    </template>

  </tr>
  <slot name="header.after">

  </slot>
  </thead>
</template>

<script>
export default {
  name: "wsTableHeader",
  props : {
    props : {
      type : Object,
      default() { return { headers : [] } }
    },
    on : {
      type : Object,
    },
    selectable : {
      type : Boolean,
      default : false
    },
    allSelected : {
      type : Boolean,
      default : false
    },
    semiSelected : {
      type : Boolean,
      default : false
    },
    multipleActions : {
      type : Array,
      default : () => []
    },
    colspanValue : {
      type : Number,
    },
    countTotal : {
      type : Number
    },
    countSelected : {
      type : Number
    },
    enableDrag : {
      type : Boolean,
      default : false
    },
  },
  data() {
    return {
      multipleActionsPosition : 0,
      totalItemsPosition : 0,
      foldedBreakPoints : [],
      lastPosition : 0,
    }
  },
  computed : {
    multipleActionsFiltered() {
      let items = [...this.multipleActions]
      if ( items.length === 0 ) {
        return []
      }
      if ( this.foldedBreakPoints.length > 0) {
        items.splice(-this.foldedBreakPoints.length)
      }
      return items
    },
    foldedItems() {
      let items =  [...this.multipleActions]
      if ( items.length === 0 ) {
        return []
      }
      if ( this.foldedBreakPoints.length > 0) {
        return items.slice(-this.foldedBreakPoints.length )
      }
      return []
    },
    selectIcon() {
      if ( this.allSelected) {
        return 'mdi-checkbox-marked-outline'
      }
      if ( this.semiSelected) {
        return 'mdi-checkbox-intermediate'
      }

      return 'mdi-checkbox-blank-outline'
    },
    headersFiltered() {

      let headers = this.props.headers
      if ( headers.length === 0) {
        return []
      }
      // headers = headers.filter(el => el.align !== 'd-none')

      return headers

    },
    selectColumnStyle() {
      return 'border-radius: 8px 0 0 0  !important;'
    },
  },
  watch : {
    semiSelected(value) {
      if ( value ) {
        this.countSelectableItemsSizes()
        setTimeout(this.countSelectableItemsSizes,50)
      }
    },
    WINDOW_WIDTH() {
      this.countSelectableItemsSizes()
    }
  },
  methods : {
    getMultipleActionPositions() {
      let sizes = [];

      this.multipleActions.forEach( (_,i) => {
        let action = this.$refs[`multiple_action_${i}`]
        if (!action) {
          return []
        }
        if (!action) {
          return []
        }
        if (action.length === 0) {
          return []
        }
        const box = action[0].$el.getBoundingClientRect()
        sizes.push((box.x + box.width))
      })

      return sizes

    },
    handleMultipleActionsFolding() {

      if ( this.foldedBreakPoints.length === 0 && this.totalItemsPosition <= this.multipleActionsPosition  ) {
        this.getMultipleActionPositions().forEach(position => {
          if ( this.totalItemsPosition <= position  ) {
            this.foldedBreakPoints.unshift(position)
          }
        })
      }

      else if ( this.foldedBreakPoints.length > 0 && this.totalItemsPosition <= this.multipleActionsPosition ) {
        this.foldedBreakPoints.push(this.multipleActionsPosition)
      }

      else if ( this.foldedBreakPoints.length > 0 && this.totalItemsPosition > this.foldedBreakPoints[this.foldedBreakPoints.length - 1] ) {
        this.foldedBreakPoints.pop()
      }


    },

    countSelectableItemsSizes() {
      if (!this.selectable || !this.semiSelected) {
        return
      }

      const items = this.$refs.multiple_items_action
      const total = this.$refs.selected_count

      if ( !items || !total ) {
        return
      }
      const itemsBox = items.getBoundingClientRect()
      const totalBox = total.getBoundingClientRect()

      this.multipleActionsPosition = itemsBox.x + itemsBox.width
      this.totalItemsPosition = totalBox.x

      this.handleMultipleActionsFolding()
    },
    getColumnStyle(i , header ) {
      let style = `border-color : ${this.wsBACKGROUND};`
      style += 'color : #666A6D; '
      if ( i === 0 && !this.selectable) {
        style += 'border-radius: 8px 0 0 0  !important;'
      }
      if ( i === this.props.headers.length -1) {
        style += 'border-radius: 0 8px 0 0  !important;'
      }

      if ( header.width ) {
        style += `width : ${header.width}px;`
      }

      return style
    },
    sortOn(header) {
      return this.props.options.sortBy.includes(header)
    },
    sortDesc() {
      return this.props.options.sortDesc[0]
    }
  },
  mounted() {
    this.countSelectableItemsSizes()
  }
}
</script>

<style scoped>
.rotate {
  transform: rotate(180deg);

}
</style>